import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import OrganizationService from '../api/OrganizationService.js'
import Spinner from '../../base/common/Spinner.js'


class OrganizationDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            isFetching: false,
            errorMessage: ''
        }
        this.refreshOrganization = this.refreshOrganization.bind(this)
        this.previousPage = this.previousPage.bind(this)
    }

    componentDidMount() {
        this.refreshOrganization()
    }
    refreshOrganization() {
        this.setState({ ...this.state, isFetching: true });
        OrganizationService.organizations()
            .then(
                responce => {

                    this.setState({ organizations: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({ errorMessage: e.message });
            })
    }
    previousPage() {
        this.props.history.push('/organizations')
    }


    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <button className="btn btn-primary "
                        onClick={this.previousPage} onKeyPress={this.previousPage}
                        style={{ marginLeft: "auto" }}> ❮ Previous
                    </button>
                </div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div>
                        {this.state.organizations.filter(org => org.id.includes(this.props.match.params.adOrgId))
                            .map(
                                organization =>
                                    <div key={organization.id} >
                                        <div className='fl w-100 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc  bg-light-blue shadow-5'
                                        >
                                            <h4 className='link dim black navy'>{organization.name}</h4>
                                            <div className='fl w-25 link dim black tl'>
                                                <h6> Contact No. </h6>
                                                <h6> Email-ID </h6>
                                                <h6> PAN No.</h6>
                                                <h6> Region </h6>
                                                <h6> City </h6>
                                                <h6> Country </h6>
                                                <h6> Address </h6>
                                            </div>
                                            <div className='fl w-70 link dim black orange tl'>
                                                <h6> {organization.contactNo} </h6>
                                                <h6> {organization.email} </h6>
                                                <h6> {organization.pan} </h6>
                                                <h6> {organization.regionName} </h6>
                                                <h6> {organization.cityName} </h6>
                                                <h6> {organization.countryName} </h6>
                                                <h6> {organization.addressLine1} </h6>
                                                <h6> {organization.addressLine2}</h6>
                                            </div>
                                            <div>
                                                <iframe title={organization.name} className='googlemap' src={organization.imagePath}></iframe>
                                            </div>
                                        </div>
                                    </div>

                            )
                        }
                    </div>
                </Scroll>

            </div>)
    }


}

export default OrganizationDetails

