import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class VehicleTrackingService  {

    customerLocation  () {
        let adUserId = sessionStorage.getItem('auhenticatedUserId') 
        return axios.get(`${Constants.URL}${Constants.CustomerLocation }?adUserId=${adUserId}`);
     }
    
    vehicleTracking (adRoleId) {
        return axios.post(`${Constants.URLMobileApp}${Constants.VehicleTracking}`, {
            location: `${adRoleId}`         
          });
     }
    
}

export default new VehicleTrackingService()