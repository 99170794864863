import axios from 'axios'
import * as Constants from '../../common/Constants.js'

class MenuService  {
    retriveAllMenus () {
         return axios.get(`${Constants.URL}${Constants.CustomerportalMenu}`);
    }

    retriveAllSubMenusDeirect () {
    return axios.get(`${Constants.URL}${Constants.CustomerportalSubMenuDirect}`);
    }
    retriveAllSubMenus (adMenuId) {
        let adRoleId = sessionStorage.getItem('auhenticatedRoleId') 
        return axios.get(`${Constants.URL}${Constants.CustomerportalSubMenu}?adRoleId=${adRoleId}&gbcpMenuId=${adMenuId}`);
     }
}

export default new MenuService()