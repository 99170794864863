import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class ContactUsService  {

    department  () {
        return axios.get(`${Constants.OB_URL}${Constants.Department }`);
     }
    
     contactDetails(departmentId) {
        return axios.get(`${Constants.URLMobileApp}${Constants.ContactList}?department=${departmentId}`);
     }
    
}

export default new ContactUsService()