import React, { Component } from 'react'
import CustomerPortalApp from './authentication/CustomerPortalApp'
import './bootstrap.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        {/*<Counter/>*/} 
        <CustomerPortalApp/>
        </div>
    )
  }
}

export default App