import React, { Component } from 'react'
import MaterialTable from "material-table";
import ReportService from '../api/ReportService.js'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'


class HybridWiseStockReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      isFetching: false,
      errorMessage:''

    }
    this.refreshReports = this.refreshReports.bind(this)
  }
  componentDidMount() {
    this.refreshReports()
    window.scrollTo(0, 0)
  }
  refreshReports() {
    this.setState({ ...this.state, isFetching: true });
    ReportService.hybridWiseStockReport()
      .then(
        responce => {
          console.log(responce.data.data)
          this.setState({ reports: responce.data.data, isFetching: false })
        }
      ).catch((e) => {
        this.setState({ ...this.state, isFetching: false });
        this.setState({errorMessage: e.message});
      })
  }


  render() {
    const data = this.state.reports;

    const columns = [
      {
        title: "Location",
        field: "Location",
      },
      {
        title: "Customer Department",
        field: "Customerdepartment",
      },
      {
        title: "Product",
        field: "Productname",
      },
      {
        title: "Hybrid",
        field: "Hybrid",
      },
      {
        title: "Chamber",
        field: "Chamber",
      },
      {
        title: "Grill Type",
        field: "Grill_Type",
      },
      {
        title: "Handling Quantity",
        field: "Handling_Quantity",
        // type: 'numeric'
      },
      {
        title: "Handling UOM",
        field: "Handling_UOM",
      },
      {
        title: "Quantity",
        field: "Quantity",
        //type: 'numeric'
      },
      {
        title: "Quantity UOM",
        field: "Quantity_UOM",
      }

    ];

    return (
      <div>
        {this.state.isFetching && <Spinner />}
        {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
        <Scroll>
          <div className="table table-bordered table-hover ">
            <MaterialTable
              title="Hybrid Wise Stock Report"
              data={data}
              columns={columns}
              options={{
                search: true,
                paging: true,
                filtering: true,
                exportButton: true,
                headerStyle: {
                  fontSize: 14,
                  backgroundColor: '#6c757d',
                  color: '#fff'
                },
                rowStyle: {
                  fontSize: 12,

                },
                fixedColumns: {
                  left: 1,
                  right: 0
                },
                fixedRows: {
                  top: 2
                },
                pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, 10000, 100000]
              }} />
          </div>
        </Scroll>
      </div>
    )


  }
}

export default HybridWiseStockReport