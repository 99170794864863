import { withRouter } from 'react-router';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthenticationService from '../../authentication/AuthenticationService.js'
import Logo from '../../../src/image/90-X-300.png'

class HeaderComponent extends Component {

    render() {
        const isUserLoggedIn = AuthenticationService.isUserLoggedIn()
        return (
            <div basic-wrapper="true">
                <nav className="navbar navbar-expand-sm bg-light ">
                    <div> <a href="http://gubbagroup.com/" className="navbar-band "> <img className = "img-fluid" alt="Gubba Cold Storage" src={Logo}/>
                    </a> </div>
                    <ul className="navbar-nav">
                        {isUserLoggedIn && <li className="nav-link">
                            <Link to="/menu/" className="nav-link"> Home</Link>
                        </li>}

                    </ul>
                    <ul className="navbar-nav navbar-collapse justify-content-end">
                        {!isUserLoggedIn && <li><Link className="nav-link" to="/login">Login</Link></li>}
                        {isUserLoggedIn && <li><Link className="nav-link" to="/logout" onClick={AuthenticationService.logout}>Logout</Link></li>}
                    </ul>
                </nav>
            </div>
        )
    }
}


export default withRouter(HeaderComponent)