import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import VehicleTrackingService from '../api/VehicleTrackingService.js'


class CustomerLocation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerlocations: [],
            isFetching: false,
            errorMessage:''
        }
        this.refreshCustomerLocation = this.refreshCustomerLocation.bind(this)
    }

    componentDidMount() {
        this.refreshCustomerLocation()
        window.scrollTo(0, 0)
    }
    refreshCustomerLocation() {
        this.setState({ ...this.state, isFetching: true });
        VehicleTrackingService.customerLocation()
            .then(
                responce => {
                    this.setState({ customerlocations: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })
    }

    subMenuClicked(adOrgId) {
        this.props.history.push(`/vehicletracking/${adOrgId}`)
    }

    render() {
        return (
            <div>
                 {this.state.isFetching && <Spinner />}
                 {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div>
                        {
                            this.state.customerlocations.map(
                                customerlocation =>

                                    <div key={customerlocation.adOrgId} >
                                        <div className='fl w-40 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'
                                            onClick={() => this.subMenuClicked(customerlocation.adOrgId)} >
                                            <h5 className='link dim black navy'>{customerlocation.Name}</h5>

                                        </div>
                                    </div>

                            )
                        }
                    </div>
                </Scroll>

            </div>)
    }


}




export default CustomerLocation

