import React, { Component } from 'react'
import { Formik, Form,  ErrorMessage } from 'formik'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class InwardOutwardMovement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
             dateFrom: '',
            dateTo: '',
            accoutstatments: [],
            isFetching: false,
            errorMessage:''
        }
         this.onSubmit = this.onSubmit.bind(this)
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
        this.onChangeDateTo = this.onChangeDateTo.bind(this)
      
    }
   
    onChangeDateFrom(e) {
        this.setState({ dateFrom: e })
    }
    onChangeDateTo(e) {
        this.setState({ dateTo: e })
    }
   

    onSubmit(values) {
   
        let dateFrom = moment(this.state.dateFrom).format('DD-MM-yyyy')
        let dateTo = moment(this.state.dateTo).format('DD-MM-yyyy')
        this.props.history.push(`/inwardoutwardmovementreport/${dateFrom}/${dateTo}`)
        this.setState({ dateFrom: '' })
        this.setState({ dateTo: '' })
        

    }

    render() {
        let { dateFrom, dateTo } = this.state

        return <div>
            <h1>Inward Outward Movement </h1>
            {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
            {this.state.isFetching && <Spinner/>}
            <Scroll>
                <div className="container" >
                    <Formik initialValues={{ dateFrom, dateTo }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="dateFrom" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="dateTo" component="div"
                                        className="alert alert-warning" />
                           
                                    <fieldset className="form-group">
                                        <label>Date From</label>
                                        <br></br>
                                        <DatePicker className="form-control" name="dateFrom" selected={dateFrom} onChange={this.onChangeDateFrom}
                                        />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Date To</label>
                                        <br></br>
                                        <DatePicker className="form-control" name="dateTo" selected={dateTo} onChange={this.onChangeDateTo}
                                        />
                                    </fieldset>
                                 <button className="btn btn-success" type="submit" onSubmit={this.onSubmit}> Submit </button>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </Scroll>
        </div>



    }
}
export default InwardOutwardMovement