import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import ContactUsService from '../api/ContactUsService.js'
import Spinner from '../../base/common/Spinner.js'


class Departments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            departments: [],
            isFetching: false,
            errorMessage: ''
        }
        this.refreshDepartment = this.refreshDepartment.bind(this)
    }

    componentDidMount() {
        this.refreshDepartment()
        window.scrollTo(0, 0)

    }
    refreshDepartment() {
        this.setState({ ...this.state, isFetching: true });
        ContactUsService.department()
            .then(
                responce => {
                    this.setState({ departments: responce.data.response.data,isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({ errorMessage: e.message });
            })
    }

    departmentClicked(departmentId) {
        this.props.history.push(`/contactdetails/${departmentId}`)

    }
    render() {
        return (
            <div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div>
                        {
                            this.state.departments.map(
                                department =>
                                    <div key={department.id} >
                                        <div className='fl w-40 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'
                                            onClick={() => this.departmentClicked(department.id)}>
                                            <h5 className='link dim black navy'>{department.name}</h5>
                                        </div>
                                    </div>

                            )
                        }
                    </div>

                </Scroll>

            </div>)
    }


}

export default Departments

