export const URL = "https://erp.gubbagroup.com/gubbaerp/ws/com.gubba.customerportal."
export const OB_URL = "https://erp.gubbagroup.com/gubbaerp/org.openbravo.service.json.jsonrest/"
export const URLMobileApp = "https://erp.gubbagroup.com/gubbaerp/ws/com.gubba.mobileapps."
export const CustomerportalLogin = "CustomerportalLogin" 
export const CustomerportalMenu = "CustomerportalMenu"
export const CustomerportalSubMenu = "CustomerportalSubMenu"
export const CustomerportalSubMenuDirect = "CustomerportalSubMenuDirect"
export const AsOnQuantityReport = "AsOnQuantityReport"
export const InwardOutwardMovementReport = "InwardOutwardMovementReport"
export const HybridWiseStockReport = "HybridWiseStockReport"
export const CustomerLocation = "CustomerLocation"
export const CustomerOrginization = "CustomerOrganization"
export const VehicleTracking = "trackVehicle"
export const Department = "GCRM_Department"
export const ContactList = "getContactList"
export const OrganizationList = "getOrganizationList?gnc=N"
export const ComplaintFeedback = "complaintFeedback"
export const CustomerAddingComplaint = "gcrm_complaint"
export const CustomerAddingComplaintUploadfile = "ComplaintsFeedback"
export const Prealert = "sqt_prealert"
export const BagType = "SQT_Bagtype"
export const Notifications = "gbcp_notification"
export const AccountStatement = "AccountStatementReport"

