
import React, { Component } from 'react'

class FooterComponent extends Component {
    render() {
        return (
            <div basic-wrapper="true">
            <footer className="footer">
                <span className="text-muted">Copyright © 2020 Gubba Cold Storage Ltd, All Right Reserved
                | Designed & Developed by  <a href="http://digiopentechnologies.com/" className="text-muted" > DigiOpen Technologies </a>  </span>

            </footer>
            </div>
        )
    }
}


export default FooterComponent