import React, {Component} from 'react'

class LogoutCompnent extends Component {
    render(){
        return(
            <div>
                <h1>You are logged out</h1>
                <div className="container"> 
                    Thank you for using Our application.
                </div>
            </div>
        )
    }
}

export default LogoutCompnent