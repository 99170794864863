import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class ReportService  {
    
    asOnQuantityReport () {
        let adUserId = sessionStorage.getItem('auhenticatedUserId') 
        return axios.get(`${Constants.URL}${Constants.AsOnQuantityReport}?adUserId=${adUserId}`);
     }

     inwardOutwardMovementReport (dateFrom, dateTo) {
        let adUserId = sessionStorage.getItem('auhenticatedUserId') 
        return axios.get(`${Constants.URL}${Constants.InwardOutwardMovementReport}?adUserId=${adUserId}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
     }
     hybridWiseStockReport () {
      let adUserId = sessionStorage.getItem('auhenticatedUserId') 
      return axios.get(`${Constants.URL}${Constants.HybridWiseStockReport}?adUserId=${adUserId}`);
   }
}

export default new ReportService()