import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import VehicleTrackingService from '../../vehicletracking/api/VehicleTrackingService.js'
import Moment from 'moment'
import TruckImage from '../../../src/image/truckfront.png'


class VehicleTracking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vehicletrackings: [],
            isFetching: false,
            errorMessage:''
        }
        this.refreshVehicletracking = this.refreshVehicletracking.bind(this)
        this.previousPage = this.previousPage.bind(this)
    }

    componentDidMount() {
        this.refreshVehicletracking()
        window.scrollTo(0, 0)
    }
    refreshVehicletracking() {
        this.setState({ ...this.state, isFetching: true });
        VehicleTrackingService.vehicleTracking(this.props.match.params.adOrgId)
            .then(
                responce => {
                    this.setState({ vehicletrackings: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })

    }
    previousPage() {
        this.props.history.push('/customerlocation')
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <button className="btn btn-primary "
                        onClick={this.previousPage} onKeyPress={this.previousPage}
                        style={{ marginLeft: "auto" }}> ❮ Previous
                    </button>
                </div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div>
                        {

                            this.state.vehicletrackings.map(
                                vehicletracking =>
                                    <div key={vehicletracking.status} >
                                        <div className='fl w-50 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc  bg-light-blue shadow-5'>
                                            <h5 className='link dim black navy'>{vehicletracking.status} {vehicletracking.count} </h5>
                                            {
                                                (typeof (vehicletracking.vehicle) == 'object') ?
                                                    <div>
                                                        {
                                                            vehicletracking.vehicle.map(vehicle =>
                                                                <div className=' fl w-25 link dim black orange'>
                                                                    <img alt="" className='truck' src={TruckImage} />
                                                                    <h6> {vehicle.inout} </h6>
                                                                    <h6> {Moment(vehicle.dateTime).format('DD MM YYYY hh:mm:ss')} </h6>
                                                                    <h6> {vehicle.vehicleno} </h6>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>

                            )
                        }
                    </div>
                </Scroll>

            </div>)
    }


}




export default VehicleTracking

