import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class NotificationsService {
    notification(locations) {
        return axios.get(`${Constants.OB_URL}${Constants.Notifications}?_where=process='Y' and organization in (${locations})&_orderBy=updated Desc&_startRow=0&_endRow=10`);
    }

}

export default new NotificationsService()