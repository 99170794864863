import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class ComplaintFeedbackService {

     complaintFeedback() {
          return axios.get(`${Constants.URLMobileApp}${Constants.ComplaintFeedback}`);
     }

     addComplaintFeedback(complaintFeedback) {
          return axios.post(`${Constants.OB_URL}${Constants.CustomerAddingComplaint}`, complaintFeedback);
     }

     uploadfileComplaintFeedback(complaintFeedbackFile) {
          return axios.post(`${Constants.URL}${Constants.CustomerAddingComplaintUploadfile}`, complaintFeedbackFile
          );
     }


}

export default new ComplaintFeedbackService()