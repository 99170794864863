import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import ComplaintFeedbackService from '../api/ComplaintFeedbackService.js'
import VehicleTrackingService from '../../../src/vehicletracking/api/VehicleTrackingService.js'
import moment from 'moment'

class AddComplaintFeedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            isFetching: false,
            description: '',
            location: '',
            type: '',
            file: '',
            base64: '',
            errorMessage: '',
            isfile: false
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.refreshComplaintFeedback = this.refreshComplaintFeedback.bind(this)
        this.onChange = this.onChange.bind(this)
    }
    componentDidMount() {
        this.refreshComplaintFeedback()
    }
    refreshComplaintFeedback() {
        this.setState({ ...this.state, isFetching: true });
        VehicleTrackingService.customerLocation()
            .then(
                responce => {
                    this.setState({ organizations: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({ errorMessage: e.message });
            })
    }
    validate(values) {
        let errors = {}
        if (!values.location) {
            errors.location = 'Enter a Location'
        }
        if (!values.type) {
            errors.type = 'Enter a Type'
        }
        if (!values.description) {
            errors.description = 'Enter a Description'
        } else if (values.description.length < 20) {
            errors.description = 'Descrition lenght should be greater that 20 '
        }
        return errors
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
        this.setState({ isfile: true })
        window.scrollTo(0, 0)
    }

    onSubmit(values) {
        let currentDate = new Date();
        let complaintFeedback = {
            "data": {
                "organization": values.location,
                "complaint": values.type,
                "companyName": sessionStorage.getItem('auhenticatedCustomerId'),
                "description": values.description,
                "customerName": sessionStorage.getItem('auhenticatedUserId'),
                "complaintdate": moment({ currentDate }).format('YYYY-MM-DD')
            }

        }
        ComplaintFeedbackService.addComplaintFeedback(complaintFeedback).then(responce => {
            if (this.state.isfile === true) {
                let reader = new FileReader();
                //  reader.readAsBinaryString(this.state.file);
                reader.readAsDataURL(this.state.file)
                reader.onload = () => {
                    this.setState({ base64: reader.result });

                    let complaintFeedbackFile = {
                        "complaintFeedbackId": responce.data.response.data[0].id,
                        "file": this.state.base64,
                        "filetype": this.state.file.type,
                        "filename": this.state.file.name
                    }
                    ComplaintFeedbackService.uploadfileComplaintFeedback(complaintFeedbackFile).then(

                    )

                }
            }
            this.props.history.push('/complaintfeedbacks')
        }


        )
            .catch((e) => {
                this.setState({ errorMessage: e.message });
            });

    }

    render() {
        let { description, location, type } = this.state
        return <div>
            <h1>Complaint And Feedback</h1>
            {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
            {this.state.isFetching && <Spinner />}
            <Scroll>
                <div className="container" >
                    <Formik initialValues={{ description, location, type }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="location" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="type" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="description" component="div"
                                        className="alert alert-warning" />
                                    <fieldset className="form-group">
                                        <label>Location</label>
                                        <Field className="form-control" as="select" name="location">
                                            <option value=""></option>
                                            {this.state.organizations.map(
                                                organization =>
                                                    <option key={organization.adOrgId} value={organization.adOrgId}>{organization.Name}</option>
                                            )
                                            }
                                        </Field>
                                    </fieldset>
                                    <fieldset>
                                        <label>Type</label>
                                        <Field className="form-control" as="select" name="type">
                                            <option value=""></option>
                                            <option value="Complaint">Complaint</option>
                                            <option value="Feedback">Feedback</option>
                                        </Field>
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Description</label>
                                        <Field className="form-control" type="text" name="description" />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Upload Image</label>
                                        <Field className="form-control" type="file" name="file" accept="image/*" onChange={this.onChange} />
                                    </fieldset>

                                    <button className="btn btn-success" type="submit"> Save </button>

                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </Scroll>
        </div>

    }
}
export default AddComplaintFeedback