import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import PrealertService from '../api/PrealertService.js'
import MaterialTable from "material-table";
import Spinner from '../../base/common/Spinner.js'

class Prealert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prealerts: [],
            isFetching: false,
            errorMessage: ''
        }
        this.refreshPrealert = this.refreshPrealert.bind(this)
        this.addPrealert = this.addPrealert.bind(this)
    }

    componentDidMount() {
        this.refreshPrealert()
        window.scrollTo(0, 0)
    }
    refreshPrealert() {
        this.setState({ ...this.state, isFetching: true });
        PrealertService.prealert()
            .then(
                responce => {
                    this.setState({prealerts: responce.data.response.data, isFetching: false})
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({ errorMessage: e.message });
            })
    }

    addPrealert() {
        this.props.history.push(`/addprealert`)
        
    }

    render() {
        const data = this.state.prealerts;
        const columns = [
            {
                title: "Location",
                field: "organization$_identifier",
            },
            {
                title: "Transaction Type",
                field: "transactiontype",
            },
            {
                title: "Document No",
                field: "documentno",
            },
            {
                title: "Scheduled Date",
                field: "scheduledfromtime",
                type: 'date'

            },
            {
                title: "Pre-Alert Status",
                field: "prealertstatus",
            }

        ];
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <button className="btn btn-primary "
                        onClick={this.addPrealert} onKeyPress={this.addPrealert}
                        style={{ marginLeft: "auto" }} > + Add New
                    </button>
                </div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div className="table table-bordered table-hover ">
                        <MaterialTable
                            title="Pre-Alert"
                            data={data}
                            columns={columns}
                            options={{
                                search: true,
                                paging: true,
                                filtering: true,
                                // exportButton: true,
                                headerStyle: {
                                    fontSize: 14,
                                    backgroundColor: '#6c757d',
                                    color: '#fff'
                                },
                                rowStyle: {
                                    fontSize: 12,

                                },
                                fixedColumns: {
                                    left: 1,
                                    right: 0
                                },
                                fixedRows: {
                                    top: 2
                                },
                                pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, 10000, 100000]
                            }} />
                    </div>

                </Scroll>

            </div>)
    }


}

export default Prealert

