import React, { Component } from 'react';
import AuthenticationService from '../../../authentication/AuthenticationService.js'

class LoginComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            adUserId: '',
            adRoleId: '',
            user: [],
            hasLoginFailed: false,
            showSuccesssMesssage: false,
            errorMessage:''
        }

        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    loginClicked() {
        AuthenticationService.executeBasicAuthenticationService(
            this.state.username, this.state.password)
            .then(responce => {
                this.setState({ user: responce.data.data })
                
                this.props.history.push('/menu')
                AuthenticationService.registerSuccessfulLogin(this.state.username, this.state.password, this.state.user)
            })
            .catch((err) => {
                this.setState({ showSuccesssMesssage: false })
                this.setState({ hasLoginFailed: true })
                this.setState({errorMessage: err.message});
            })

             AuthenticationService.executeBasicAuthenticationService(
            this.state.username, this.state.password)
            .then(responce => {
                this.setState({ user: responce.data.data })
                console.log(this.state.user)
                this.props.history.push('/menu')
                AuthenticationService.registerSuccessfulLogin(this.state.username, this.state.password, this.state.user)
            })
            .catch((err) => {
                this.setState({ showSuccesssMesssage: false })
                this.setState({ hasLoginFailed: true })
                this.setState({errorMessage: err.message});
            })

    }


    render() {
      
        return (
            <div basic-wrapper="true" >
            <div className="login-form">
                <h2 className="text-center">Sign in</h2>
                <div className="container">
                    {/*<ShowInvalidCredentials hasLoginFailed = {this.state.hasLoginFailed} /> */}
                    {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                    {this.state.hasLoginFailed && <div className="alert alert-danger"> Invalid Credentials</div>}                    
                    {this.state.showSuccesssMesssage && <div> Login</div>}

                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="fa fa-user"></span>
                                </span>
                            </div>
                            <input type="text" name="username" className="form-control" placeholder="Username" required="required" value={this.state.username}
                                onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group" >
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fa fa-lock"></i>
                                </span>
                            </div>
                            <input type="password" name="password" className="form-control" placeholder="Password" required="required"
                                value={this.state.password} onChange={this.handleChange} />
                        </div>
                    </div >
                    <div className="form-group">
                        <button className="btn btn-primary login-btn btn-block" onClick={this.loginClicked} onKeyPress={this.loginClicked}> Sign in </button>
                    </div>

                </div>
            </div>
            </div>
        )
    }
}




export default LoginComponent