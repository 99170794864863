import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import ContactUsService from '../api/ContactUsService.js'
import Spinner from '../../base/common/Spinner.js'

class ContactDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contacts: [],
            isFetching: false,
            errorMessage:''
        }
        this.refreshContactDetails = this.refreshContactDetails.bind(this)
        this.previousPage = this.previousPage.bind(this)
    }

    componentDidMount() {
        this.refreshContactDetails()
        window.scrollTo(0, 0)
    }
    refreshContactDetails() {
        this.setState({ ...this.state, isFetching: true });
        ContactUsService.contactDetails(this.props.match.params.departmentId)
            .then(
                responce => {
                  this.setState({ contacts: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })
    }
    previousPage() {
        this.props.history.push('/departments')
    }
    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <button className="btn btn-primary "
                        onClick={this.previousPage} onKeyPress={this.previousPage}
                        style={{ marginLeft: "auto" }}> ❮ Previous
                    </button>
                </div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <div>
                    <Scroll>
                        <div>
                            {
                                this.state.contacts.map(
                                    contact =>
                                        <div key={contact.contact} >
                                            <div className='fl w-50 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc bg-light-blue shadow-5'>
                                                <h2 className='link dim black navy'>{contact.name}</h2>
                                                <div className=' fl w-100'>
                                                    <div className=' fl w-100 link dim black orange'>
                                                        <h6>{contact.position} </h6>
                                                        <h6>{contact.contact} </h6>
                                                        <h6>{contact.location} </h6>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                )
                            }

                        </div>
                    </Scroll>
                </div>
            </div>)
    }


}




export default ContactDetails

