import React from 'react';
import LoadingScreen from 'react-loading-screen';
const Spinner = (props) => {
  return (
    <div className="">

      <LoadingScreen
          loading={true}
          bgColor='#e6eeff'
          spinnerColor='#b3ccff'
          textColor='#676767'
          logoSrc='/90-X-300.png'
          text='Loading ...'
          width= '1%'
         height= '30px'
        > </LoadingScreen>
    </div>
  );
};

export default Spinner;