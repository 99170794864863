import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'
class OrganizationService  {
      organizations() {
        return axios.get(`${Constants.URLMobileApp}${Constants.OrganizationList}`);
   
     }  
     
}

export default new OrganizationService()