import React, { Component } from 'react';
import MenuService from '../api/MenuService.js'
import Scroll from '../../common/Scroll.js'
import Spinner from '../../common/Spinner.js'

class MenuComponet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [],
            menuDirects: [],
            isFetching: false,
            errorMessage:''
        }

        this.refreshMenus = this.refreshMenus.bind(this)
        this.refreshMenuDirects = this.refreshMenuDirects.bind(this)
        this.subMenuDirectClicked = this.subMenuDirectClicked.bind(this)
        this.subMenuClicked = this.subMenuClicked.bind(this)
    }


    componentDidMount() {
       this.refreshMenus()
       this.refreshMenuDirects()
       window.scrollTo(0, 0)
    }
    refreshMenus() {
        this.setState({ ...this.state, isFetching: true });
        MenuService.retriveAllMenus()
            .then(
                responce => {
                    console.log(responce.data.data)
                    this.setState({ menus: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })
     
    }
    refreshMenuDirects() {
        MenuService.retriveAllSubMenusDeirect()
            .then(
                responce => {
                  this.setState({ menuDirects: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
              })
    }

    subMenuClicked(adMenuId) {
           this.props.history.push(`/submenu/${adMenuId}`)
    }

    subMenuDirectClicked(windowName) {
       
        this.props.history.push(`/${windowName}`)
 }
    render() {
        return (
            <div>
                <Scroll>
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                {this.state.isFetching && <Spinner />}
                    <div>
                                {
                                    this.state.menus.map(
                                        menu =>
                                             <div key={menu.adMenuId} >
                                                      <div className='fl w-30 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'                            
                                                        onClick={() => this.subMenuClicked(menu.adMenuId)} >
                                                        <h4 className = 'link dim black navy'>{menu.name}</h4>
                                                        <h6 className = 'link dim black orange' >{menu.description}</h6>
                                                      </div>
                                            </div>
                                                                                      
                                    )
                                }
                                 {
                                    this.state.menuDirects.map(
                                        menuDirect =>
                                             <div key={menuDirect.adMenuId} >
                                                      <div className='fl w-30 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'                            
                                                        onClick={() => this.subMenuDirectClicked(menuDirect.windowName)} >
                                                        <h4 className = 'link dim black navy'>{menuDirect.name}</h4>
                                                        <h6 className = 'link dim black orange' >{menuDirect.description}</h6>
                                                      </div>
                                            </div>
                                                                                      
                                    )
                                }
                    </div>               
                </Scroll>

            </div>)
    }

}


export default MenuComponet