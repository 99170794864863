import React, { Component } from 'react';
import MenuService from '../api/MenuService.js'
import Scroll from '../../common/Scroll.js'
import Spinner from '../../common/Spinner.js'

class SubMenuComponet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submenus: [],
            isFetching: false,
            errorMessage: ''            
        }
        this.refreshSubMenus = this.refreshSubMenus.bind(this)
    }

    componentDidMount() {
        this.refreshSubMenus()
        window.scrollTo(0, 0);
    }
    refreshSubMenus() {
        this.setState({ ...this.state, isFetching: true });
        MenuService.retriveAllSubMenus(this.props.match.params.adMenuId)
            .then(
                responce => {
                    this.setState({ submenus: responce.data.data, isFetching: false})
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })
    }

    subMenuClicked(windowName){
        this.props.history.push(`/${windowName}`)
    }
    render() {
        
        return (<div>
              {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
              {this.state.isFetching && <Spinner />}
                <Scroll>
                    <div className="menuflex">
                                {
                                    this.state.submenus.map(
                                        submenu =>
                                                <div key={submenu.gbcpMenuId} >
                                                    <div className='fl w-30 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'
                                                        onClick={() => this.subMenuClicked(submenu.windowName)} >
                                                        <h4 className = 'link dim black navy' >{submenu.name}</h4>
                                                        <h6 className = 'link dim black orange'>{submenu.description}</h6>
                                                    </div>                                            
                                                 </div>
                                        )
                                }
                    </div>               
                </Scroll>
             </div> )
    }
}


export default SubMenuComponet