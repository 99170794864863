import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import Spinner from '../../base/common/Spinner.js'
import Scroll from '../../base/common/Scroll.js'
import AccountStatementService from '../../accountstatement/api/AccountStatementService.js'


class AccountStatementReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      openingbanace: '',
      orgnization:'',
      isFetching: false,
      errorMessage:''

    }
    this.refreshReports = this.refreshReports.bind(this)
  }
  componentDidMount() {
    this.refreshReports()
    window.scrollTo(0, 0)
  }
  refreshReports() {
    this.setState({ ...this.state, isFetching: true });
    AccountStatementService.accountStatement(this.props.match.params.location,
      this.props.match.params.dateFrom,
      this.props.match.params.dateTo).then(
        responce => {
          this.setState({ openingbanace: responce.data.data[0].OpeningBalance, orgnization : responce.data.data[0].Orgnization })
          this.setState({ reports: responce.data.data, isFetching: false })
        })
      .catch((e) => {
           this.setState({ ...this.state, isFetching: false });
           this.setState({errorMessage: e.message});
      });

  }


  render() {
    const data = this.state.reports;
    const columns = [
      {
        title: "Date",
        field: "Documentdate",
      },
      {
        title: "Particulars",
        field: "Particulars",
      },
      {
        title: "Debit",
        field: "Debit",
      },
      {
        title: "Credit",
        field: "Credit",
      },
      {
        title: "Balance",
        field: "Balance",
      },
      {
        title: "Cheque No",
        field: "Cheque",
      },
      {
        title: "Description",
        field: "Description",
        // type: 'numeric'
      },
      {
        title: "Location",
        field: "Location",
      }

    ];

    return (
      <div>
        {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
        {this.state.isFetching && <Spinner/>}
        <Scroll>
          <div className="table table-bordered table-hover ">

            <MaterialTable
              title={this.state.orgnization}
              data={data}
              columns={columns}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    <div style={{ padding: '0px 10px' }}>
                     <h6 className="text-left"> Ledger Report From Date : {this.props.match.params.dateFrom} To Date : {this.props.match.params.dateTo}</h6>
                      <h6>Opening Balance -   {this.state.openingbanace}</h6>
                    </div>
                  </div>
                ),
              }}
              options={{
                position: 'sticky',
                search: true,
                paging: true,
                filtering: true,
                exportButton: true,
                headerStyle: {
                  fontSize: 14,
                  backgroundColor: '#6c757d',
                  color: '#fff'
                },
                rowStyle: {
                  fontSize: 12,

                },
                fixedColumns: {
                  left: 1,
                  right: 0
                },
                fixedRows: {
                  top: 2
                },
                pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, 10000, 100000],

              }}


            />
          </div>
        </Scroll>
      </div>
    )


  }
}

export default AccountStatementReport