import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import OrganizationService from '../api/OrganizationService.js'
import Spinner from '../../base/common/Spinner.js'


class OrganizationList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            isFetching: false,
            errorMessage: ''
        }
        this.refreshOrganization = this.refreshOrganization.bind(this)
    }

    componentDidMount() {
        this.refreshOrganization()
    }
    refreshOrganization() {
        this.setState({ ...this.state, isFetching: true });
        OrganizationService.organizations()
            .then(
                responce => {
                    console.log(responce.data)
                    this.setState({ organizations: responce.data.data, isFetching: false })
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({ errorMessage: e.message });
            })
    }

    subMenuClicked(adOrgId) {
        this.props.history.push(`/organizationdetails/${adOrgId}`)
    }

    render() {
        return (
            <div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div>
                        {
                            this.state.organizations.map(
                                organization =>
                                    <div key={organization.id} >
                                        <div className='fl w-40 pa2 ba b--lightest-blue br3 pa3 dib bw2 tc grow bg-light-blue shadow-5 ma3'
                                            onClick={() => this.subMenuClicked(organization.id)} >
                                            <h5 className='link dim black navy'>{organization.name}</h5>

                                        </div>
                                    </div>

                            )
                        }
                    </div>
                </Scroll>

            </div>)
    }


}




export default OrganizationList

