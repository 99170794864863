import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class PrealertService {
     prealert() {
          let cbPartnerId = sessionStorage.getItem('auhenticatedCustomerId')
          return axios.get(`${Constants.OB_URL}${Constants.Prealert}?_where=status='Draft'and bpartner='${cbPartnerId}'`);
     }
     bagType() {
          return axios.get(`${Constants.OB_URL}${Constants.BagType}`);
     }

     addPrealert(prealert) {
       return axios.post(`${Constants.OB_URL}${Constants.Prealert}`, prealert);
     }


}

export default new PrealertService()