import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import ComplaintFeedbackService from '../api/ComplaintFeedbackService.js'
import MaterialTable from "material-table";

class ComplaintFeedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            complaintFeedbacks: [],
            isFetching: false,
            errorMessage:''
        }
        this.refreshComplaintFeedback = this.refreshComplaintFeedback.bind(this)
        this.addComplaintFeedback = this.addComplaintFeedback.bind(this)
    }

    componentDidMount() {
        this.refreshComplaintFeedback()
        window.scrollTo(0, 0)
    }
    refreshComplaintFeedback() {
        this.setState({ ...this.state, isFetching: true });
        ComplaintFeedbackService.complaintFeedback()
            .then(
                responce => {
                    this.setState({ complaintFeedbacks: responce.data.data , isFetching: false})
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
              })
    }

    addComplaintFeedback() {
        this.props.history.push(`/addcomplaintfeedbacks`)
    }

    render() {
        let adUserId = sessionStorage.getItem('auhenticatedUserId')
        const data = this.state.complaintFeedbacks.filter(user => user.customerId.includes(adUserId));

        const columns = [
            {
                title: "Type",
                field: "type",
            },
            {
                title: "Location",
                field: "locationName",
            },
            {
                title: "Complaint No",
                field: "complaintNo",
            },
            {
                title: "Status",
                field: "status",
            },
            {
                title: "Description",
                field: "complaintAndFeedbackDesc",
            },
            {
                title: "Assign To",
                field: "assignTo",
            }

        ];
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <button className="btn btn-primary "
                        onClick={this.addComplaintFeedback} onKeyPress={this.addComplaintFeedback}
                        style={{ marginLeft: "auto" }} > + Add New
                    </button>
                </div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                <Scroll>
                    <div className="table table-bordered table-hover ">
                        <MaterialTable
                            title="Complaint and Feedback"
                            data={data}
                            columns={columns}
                            options={{
                                search: true,
                                paging: true,
                                filtering: true,
                                // exportButton: true,
                                headerStyle: {
                                    fontSize: 14,
                                    backgroundColor: '#6c757d',
                                    color: '#fff'
                                },
                                rowStyle: {
                                    fontSize: 12,

                                },
                                fixedColumns: {
                                    left: 1,
                                    right: 0
                                },
                                fixedRows: {
                                    top: 2
                                },
                                pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, 10000, 100000]
                            }} />
                    </div>

                </Scroll>

            </div>)
    }


}

export default ComplaintFeedback

