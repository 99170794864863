import React , {Component} from 'react'
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from '../../src/authentication/AuthenticatedRoute'
import LoginComponent from '../../src/base/secureapp/components/LoginComponent'
import MenuComponent from '../../src/base/secureapp/components/MenuComponent'
import SubMenuComponent from '../../src/base/secureapp/components/SubMenuComponent'
import HeaderComponent from '../../src/base/common/HeaderComponent'
import FooterComponent from '../../src/base/common/FooterComponent'
import LogoutCompnent from '../../src/base/secureapp/components/LogoutCompnent'
import ErrorComponent from '../../src/base/common/ErrorComponent'
import Asonkgeport from '../../src/reports/components/Asonkgreport'
import InwardOutwardMovement from '../../src/reports/components/InwardOutwardMovement'
import InwardOutwardMovementReport from '../../src/reports/components/InwardOutwardMovementReport'
import HybridWiseStockReport from '../../src/reports/components/HybridWiseStockReport'
import CustomerLocation from '../../src/vehicletracking/components/CustomerLocation'
import VehicleTracking from '../../src/vehicletracking/components/VehicleTracking'
import Departments from '../../src/contactus/components/Departments'
import ContactDetails from '../../src/contactus/components/ContactDetails'
import OrganizationList from '../../src/locations/components/OrganizationList'
import OrganizationDetails from '../../src/locations/components/OrganizationDetails'
import ComplaintFeedback from '../../src/complaintfeedback/components/ComplaintFeedback'
import AddComplaintFeedback from '../../src/complaintfeedback/components/AddComplaintFeedback'
import Prealert from '../../src/prealert/components/Prealert'
import AddPrealert from '../../src/prealert/components/AddPrealert'
import Notifications from '../../src/notifications/components/Notifications'
import AccoutStatement from '../../src/accountstatement/components/AccoutStatement'
import AccountStatementReport from '../../src/accountstatement/components/AccountStatementReport'


class CustomerPortalApp extends Component {
    render(){ 
        return(
            <div className = "CustomerPortalApplication">
                <Router>
                    <>
                    <HeaderComponent/>
                    <Switch>
                      <Route path="/"  exact component={LoginComponent}/>
                      <Route path="/login" component={LoginComponent} />
                      <AuthenticatedRoute path="/menu" component={MenuComponent}/>
                      <AuthenticatedRoute path="/submenu/:adMenuId" component={SubMenuComponent}/>
                      <AuthenticatedRoute path="/asonkgreport" component={Asonkgeport}/>
                      <AuthenticatedRoute path="/inwardoutwardmovement" component={InwardOutwardMovement}/>
                      <AuthenticatedRoute path="/inwardoutwardmovementreport/:dateFrom/:dateTo" component={InwardOutwardMovementReport}/>
                      <AuthenticatedRoute path="/hybridwisestockreport" component={HybridWiseStockReport}/>
                      <AuthenticatedRoute path="/customerlocation" component={CustomerLocation}/>
                      <AuthenticatedRoute path="/vehicletracking/:adOrgId" component={VehicleTracking}/>
                      <AuthenticatedRoute path="/departments" component={Departments}/>
                      <AuthenticatedRoute path="/contactdetails/:departmentId" component={ContactDetails}/>
                      <AuthenticatedRoute path="/organizations" component={OrganizationList}/>
                      <AuthenticatedRoute path="/organizationdetails/:adOrgId" component={OrganizationDetails}/>
                      <AuthenticatedRoute path="/complaintfeedbacks" component={ComplaintFeedback}/>
                      <AuthenticatedRoute path="/addcomplaintfeedbacks" component={AddComplaintFeedback}/>
                      <AuthenticatedRoute path="/prealert" component={Prealert}/>
                      <AuthenticatedRoute path="/addprealert" component={AddPrealert}/>
                      <AuthenticatedRoute path="/notifications" component={Notifications}/>
                      <AuthenticatedRoute path="/accountstatement" component={AccoutStatement}/>
                      <AuthenticatedRoute path="/accountstatementreport/:location/:dateFrom/:dateTo" component={AccountStatementReport}/>

                      <AuthenticatedRoute path="/logout" component={LogoutCompnent}/>
                      <Route component={ErrorComponent}/>
                    </Switch>
                    <FooterComponent/>
                    </>
                </Router>
            </div>
        )
        
    }
}


export default CustomerPortalApp