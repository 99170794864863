import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Scroll from '../../base/common/Scroll.js'
import Spinner from '../../base/common/Spinner.js'
import AccountStatementService from '../../accountstatement/api/AccountStatementService.js'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AccoutStatement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            location:'',
            dateFrom: '',
            dateTo: '',
            accoutstatments: [],
            isFetching: false,
            errorMessage:''


        }
        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this)
        this.onChangeDateTo = this.onChangeDateTo.bind(this)
        this.refreshAccountStatementInput = this.refreshAccountStatementInput.bind(this)
    }
    componentDidMount() {
        this.refreshAccountStatementInput()
        window.scrollTo(0, 0)
    }
    refreshAccountStatementInput() {
        this.setState({ ...this.state, isFetching: true });
        AccountStatementService.customerOrganization()
            .then(
                responce => {
                    this.setState({ organizations: responce.data.data , isFetching: false})
                }
            ).catch((e) => {
                this.setState({ ...this.state, isFetching: false });
                this.setState({errorMessage: e.message});
           })
    }
    validate(values) {
        let errors = {}
        if (!values.location) {
            errors.location = 'Enter a Location'
        }
        return errors
    }

    onChangeDateFrom(e) {
        this.setState({ dateFrom: e })
    }
    onChangeDateTo(e) {
        this.setState({ dateTo: e })
    }
   

    onSubmit(values) {
   
        let location = values.location
        let dateFrom = moment(this.state.dateFrom).format('DD-MM-yyyy')
        let dateTo = moment(this.state.dateTo).format('DD-MM-yyyy')
        this.props.history.push(`/accountstatementreport/${location}/${dateFrom}/${dateTo}`)
        this.setState({ dateFrom: '' })
        this.setState({ dateTo: '' })
        this.setState({ location: '' })     

    }

    render() {
        let { location, dateFrom, dateTo } = this.state

        return <div>
            <h1>Account Statement</h1>
            {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
            {this.state.isFetching && <Spinner/>}
            <Scroll>
                <div className="container" >
                    <Formik initialValues={{ location, dateFrom, dateTo }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="location" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="dateFrom" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="dateTo" component="div"
                                        className="alert alert-warning" />
                           
                                    <fieldset className="form-group">
                                        <label>Date From</label>
                                        <br></br>
                                        <DatePicker className="form-control" name="dateFrom" selected={dateFrom} onChange={this.onChangeDateFrom}
                                        />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Date To</label>
                                        <br></br>
                                        <DatePicker className="form-control" name="dateTo" selected={dateTo} onChange={this.onChangeDateTo}
                                        />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Orginization</label>
                                        <Field className="form-control" as="select" name="location">
                                            <option value=""></option>
                                            {this.state.organizations.map(
                                                organization =>
                                                    <option key={organization.adOrgId} value={organization.adOrgId}>{organization.Name}</option>
                                            )
                                            }
                                        </Field>
                                    </fieldset>


                                    <button className="btn btn-success" type="submit" onSubmit={this.onSubmit}> Submit </button>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </Scroll>
        </div>



    }
}
export default AccoutStatement