import axios from 'axios'
import * as Constants from '../../base/common/Constants.js'

class AccountStatementService {

    customerOrganization  () {
     let adUserId = sessionStorage.getItem('auhenticatedUserId') 
     return axios.get(`${Constants.URL}${Constants.CustomerOrginization }?adUserId=${adUserId}`);
  }
     accountStatement(org, dateFrom, dateTo) {
          let adUserId = sessionStorage.getItem('auhenticatedUserId')
          return axios.get(`${Constants.URL}${Constants.AccountStatement}?adUserId=${adUserId}&adOrgId=${org}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
     }
     addPrealert(prealert) {
       return axios.post(`${Constants.OB_URL}${Constants.Prealert}`, prealert);
     }

}

export default new AccountStatementService()
