import axios from 'axios'
import * as Constants from '../base/common/Constants.js'

class AuthenticationService {


  executeBasicAuthenticationService(username, password) {
    return axios.get(`${Constants.URL}${Constants.CustomerportalLogin}/basicauth`,
      {
        headers: {
          authorization: this.createBasicAuthToken(username, password)
        }
      }
    )

  }

  createBasicAuthToken(username, password) {
    return 'Basic ' + window.btoa(username + ":" + password)
  }

  registerSuccessfulLogin(username, password, user) {
    sessionStorage.setItem('auhenticatedUser', username)
    sessionStorage.setItem('auhenticatedUserId', user[0].adUserId)
    sessionStorage.setItem('auhenticatedRoleId', user[0].adRoleId)
    sessionStorage.setItem('auhenticatedCustomerId', user[0].cBpartnerId)
    this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
  }
  logout() {
     sessionStorage.removeItem('auhenticatedUser')
     sessionStorage.removeItem('auhenticatedUserId')
     sessionStorage.removeItem('auhenticatedRoleId')
     sessionStorage.removeItem('auhenticatedCustomerId')
     }

  isUserLoggedIn() {
    let user = sessionStorage.getItem('auhenticatedUser')
    if (user === null) return false
    return true

  }
  getLoggedInUser() {
    let user = sessionStorage.getItem('auhenticatedUser')
    if (user === null) return ''
    return user

  }
  setupAxiosInterceptors(basicAuthHeader) {

    axios.interceptors.request.use(
      (config) => {
        if (this.isUserLoggedIn()) {
          config.headers.authorization = basicAuthHeader
        }
        return config
      }
    )

  }

}

export default new AuthenticationService()