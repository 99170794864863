import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Scroll from '../../base/common/Scroll.js'
import PrealertService from '../api/PrealertService.js'
import VehicleTrackingService from '../../vehicletracking/api/VehicleTrackingService.js'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddPrealert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            bagTypes: [],
            startDate: '',
            type: '',
            errorMessage:''
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.onChange = this.onChange.bind(this)
        this.refreshAddPrealert = this.refreshAddPrealert.bind(this)
    }
    componentDidMount() {
        this.refreshAddPrealert()
        window.scrollTo(0, 0)
    }
    refreshAddPrealert() {
        VehicleTrackingService.customerLocation()
            .then(
                responce => {
                    this.setState({ organizations: responce.data.data })
                }
            ).catch((e) => {
                this.setState({ errorMessage: e.message });
            })
        PrealertService.bagType()
            .then(
                responce => {
                    this.setState({ bagTypes: responce.data.response.data })
                }
            ).catch((e) => {
              this.setState({ errorMessage: e.message });
            })

    }
    validate(values) {
        let errors = {}
        if (!values.type) {
            errors.type = 'Enter a Memo Type'     
        }
        if (!values.location) {
            errors.location = 'Enter a Location'       
        }
        if (!values.bagType) {
            errors.bagType = 'Enter a Bag Type'      
        }
        var d1 = new Date(moment(this.state.startDate).format('YYYY-MM-DD'));
        var d2 = new Date();
       // d2.setDate(d2.getDate() - 1);
       // console.log(d1)
        //console.log(d2)
        if (d1.getTime() < d2.getTime()) {
            errors.startDate = 'Schedule Date should not less than current date'
       
        }

        return errors
    }

    onChange(e) {
          this.setState({ startDate: e })
          this.setState({ location: '' })  
          this.setState({ bagType: '' })  
          this.setState({ type: '' }) 
    }


    onSubmit(values) {
        let prealert = {
            "data": {
                "organization": values.location,
                "transactiontype": values.type,
                "sQTBagtype": values.bagType,
                "scheduledfromtime": moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                "bpartner": sessionStorage.getItem('auhenticatedCustomerId'),
                "prealertstatus": 'Pre-alert'
            }

        }
        PrealertService.addPrealert(prealert).then(
            this.props.history.push('/prealert')
        ).catch((e) => {
            this.setState({ errorMessage: e.message });
          })
   

    }

    render() {
        let { location, startDate, type, bagType } = this.state

        return <div>
            <h1>Pre-Alert</h1>
            {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
            <Scroll>
                <div className="container" >
                    <Formik initialValues={{ location, type, bagType, startDate }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="location" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="type" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="bagType" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="startDate" component="div"
                                        className="alert alert-warning" />
                                    <fieldset className="form-group">
                                        <label>Schedule Date</label>
                                        <br></br>
                                        <DatePicker className="form-control" name="startDate" selected={startDate} onChange={this.onChange}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={20}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa" />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Location</label>
                                        <Field className="form-control" as="select" name="location">
                                            <option value=""></option>
                                            {this.state.organizations.map(
                                                organization =>
                                                    <option key={organization.adOrgId} value={organization.adOrgId}>{organization.Name}</option>
                                            )
                                            }
                                        </Field>
                                    </fieldset>
                                    <fieldset>
                                        <label>Memo Type</label>
                                        <Field className="form-control" as="select" name="type">
                                            <option value=""></option>
                                            <option value="Inward">Inward</option>
                                            <option value="Outward">Outward</option>
                                        </Field>
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label>Bag Type</label>
                                        <Field className="form-control" as="select" name="bagType">
                                            <option value=""></option>
                                            {this.state.bagTypes.map(
                                                bagtype =>
                                                    <option key={bagtype.id} value={bagtype.id}>{bagtype.bagtype}</option>
                                            )
                                            }
                                        </Field>
                                    </fieldset>


                                    <button className="btn btn-success" type="submit" onSubmit={this.onSubmit}> Submit </button>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </Scroll>
        </div>



    }
}
export default AddPrealert