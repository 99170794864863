import React, { Component } from 'react'
import Scroll from '../../base/common/Scroll.js'
import NotificationsService from '../api/NotificationsService.js'
import VehicleTrackingService from '../../vehicletracking/api/VehicleTrackingService.js'
import Spinner from '../../base/common/Spinner.js'


class Notifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notifications: [],
            isFetching: false,
            errorMessage: ''
        }
        this.refreshNotifications = this.refreshNotifications.bind(this)

    }

    componentDidMount() {
        this.refreshNotifications()
        window.scrollTo(0, 0)
    }
    refreshNotifications() {
        this.setState({ ...this.state, isFetching: true });
        VehicleTrackingService.customerLocation()
            .then(
                responce => {
                    let locations = []
                    responce.data.data.map(location => locations.push(`'${location.adOrgId}'`))
                    NotificationsService.notification(`${locations},'0'`)
                        .then(
                            responce => {
                                this.setState({ notifications: responce.data.response.data, isFetching: false })
                            }
                        ).catch((e) => {
                            this.setState({ ...this.state, isFetching: false });
                            this.setState({ errorMessage: e.message });
                        })
                }
            )


    }

    render() {
        return (
            <div>
                <div>
                {this.state.isFetching && <Spinner />}
                {this.state.errorMessage && <div className="alert alert-danger"> {this.state.errorMessage}</div>}
                    <Scroll>
                        <div>
                            {
                                this.state.notifications.map(
                                    notification =>
                                        <div key={notification.id} >
                                            <div className='fl w-100 pa2 ba b--lightest-blue br3 pa3 dib bw2 tl bg-light-blue shadow-5'>
                                                <h6 className='link dim black navy'>{notification.description}</h6>
                                            </div>
                                        </div>

                                )
                            }

                        </div>
                    </Scroll>
                </div>
            </div>)
    }


}




export default Notifications

